import TicketOverviewShow from "./show";
import TicketOverviewShowBSM from "./show_bsm";
import TicketOverviewList from "./list";
import TicketOverviewNew from "./new";


if (document.body.getAttribute("data-current-path") === "ticket/tasks") {
    const action = document.body.getAttribute("data-current-action")
    const customView = document.querySelector(".app-ticket")?.getAttribute("data-custom-view-provider");
    console.log("customView", customView)
    if (action === "index" || action === "archive") {
        new TicketOverviewList();
    } else if(action === "show") {
        if (customView && customView === "bsm") {
            new TicketOverviewShowBSM();
        } else {
            new TicketOverviewShow();
        }
    } else if(action === "new") {
        new TicketOverviewNew();
    }
}